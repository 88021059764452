import type { Employee_Response } from '../../types/employee';
import { API_ENDPOINTS, Make_Authorized_API_Call } from '../core/apiClient';
import type { APIResult } from '../core/types';
import { pmsApi } from './pms';
export type { PMS_Summary } from './pms';

interface Employee_Params {
    Company_ID: string;
    Department_ID?: string;
    Location_ID?: string;
    Job_ID?: string;
}

export const employeeApi = {
    Get_Employees: async (params: Employee_Params): Promise<APIResult<Employee_Response>> => {
        if (!params.Company_ID) {
            throw new Error('Company_ID is required');
        }

        const queryParams = new URLSearchParams();
        queryParams.append('Company_ID', params.Company_ID);
        if (params.Department_ID) queryParams.append('Department_ID', params.Department_ID);
        if (params.Location_ID) queryParams.append('Location_ID', params.Location_ID);
        if (params.Job_ID) queryParams.append('Job_ID', params.Job_ID);

        return Make_Authorized_API_Call(
            `${API_ENDPOINTS.EMPLOYEE.BASE}?${queryParams.toString()}`,
            'GET'
        );
    },

    Get_Employee: async (Company_ID: string, Employee_ID: string): Promise<APIResult<Employee_Response>> => {
        if (!Company_ID || !Employee_ID) {
            throw new Error('Company_ID and Employee_ID are required');
        }

        return Make_Authorized_API_Call(
            `${API_ENDPOINTS.EMPLOYEE.BASE}?Company_ID=${Company_ID}&Employee_ID=${Employee_ID}`,
            'GET'
        );
    }
};

export { pmsApi };

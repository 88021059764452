import { Spinner } from 'flowbite-react';
import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ActionsProvider } from '../contexts/Actions';
import { Company_Chart_Data_Provider } from '../contexts/CompanyChartDataContext';
import { EmployeeContextProvider } from '../contexts/EmployeeContext';
import { useStatus } from '../contexts/Status';
import MainLayout from '../shared/components/layout/MainLayout';

// Super admin pages
const SuperDashboard = lazy(() => import('../features/super/pages/Dashboard'));

// Core admin pages
const AdminDashboard = lazy(() => import('../features/admin/pages/AdminDashboard'));
const Organization = lazy(() => import('../features/admin/pages/Organization'));
const DashboardDepartment = lazy(() => import('../features/admin/pages/DashboardDepartment'));
const DashboardJob = lazy(() => import('../features/admin/pages/DashboardJob'));
const DashboardLocation = lazy(() => import('../features/admin/pages/DashboardLocation'));
const PerformanceManagement = lazy(() => import('../features/admin/pages/PerformanceManagement'));

// Company pages
const HeadcountSetup = lazy(() => import('../features/company/pages/HeadcountSetup'));

// Workflow pages
const WorkflowOverview = lazy(() => import('../features/workflow/pages/Overview'));
const Actions = lazy(() => import('../features/actions/pages/Actions'));
const ActionConfig = lazy(() => import('../features/actions/pages/ActionConfig'));
const AgentTriggerConfig = lazy(() => import('../features/actions/pages/AgentTriggerConfig'));
const ApprovalGroups = lazy(() => import('../features/actions/pages/ApprovalGroups'));
const ApprovalGroupMembership = lazy(() => import('../features/approvals/pages/ApprovalGroupMembership'));
const ConfigApprovals = lazy(() => import('../features/actions/pages/ConfigApprovals'));
const WorkflowApprovals = lazy(() => import('../features/workflow/pages').then(m => ({ default: m.Approvals })));

// Analytics pages
const AnalyticsDashboard = lazy(() => import('../features/analytics/pages/AnalyticsDashboard'));

// Settings & Profile
const Profile = lazy(() => import('../features/admin/pages/Profile'));
const Settings = lazy(() => import('../features/admin/pages/Settings'));

const LoadingFallback: React.FC = () => (
  <div className="flex justify-center items-center h-screen">
    <Spinner size="xl" />
  </div>
);

const WelcomeScreen: React.FC = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="text-center">
      <h1 className="text-2xl font-bold mb-4">Welcome</h1>
      <p>Please select a company to continue.</p>
    </div>
  </div>
);

const AuthenticatedApp: React.FC = () => {
  const { Is_Super_Admin, Company_ID, Has_Client_Context } = useStatus();
  
  console.log('[AuthenticatedApp] Rendering with state:', {
    Is_Super_Admin,
    Company_ID,
    Has_Client_Context
  });

  // Super admin without client context goes to super dashboard
  if (Is_Super_Admin && !Has_Client_Context) {
    return (
      <Suspense fallback={<LoadingFallback />}>
        <Routes>
          <Route path="/" element={<Navigate to="/super/dashboard" replace />} />
          <Route path="/super/dashboard" element={<SuperDashboard />} />
          <Route path="*" element={<Navigate to="/super/dashboard" replace />} />
        </Routes>
      </Suspense>
    );
  }

  // Regular user or super admin with client context needs company ID
  if (!Company_ID) {
    return <WelcomeScreen />;
  }

  // Both regular users and super admins with context get admin routes
  return (
    <Company_Chart_Data_Provider>
      <EmployeeContextProvider Company_ID={Company_ID}>
        <ActionsProvider>
          <MainLayout>
          <Suspense fallback={<LoadingFallback />}>
            <Routes>
              {/* Core Routes */}
              <Route path="/" element={<Navigate to="/admin/dashboard" replace />} />
              <Route path="/admin/dashboard" element={<AdminDashboard />} />
              <Route path="/admin/organization" element={<Organization />} />
              <Route path="/admin/departments" element={<DashboardDepartment />} />
              <Route path="/admin/department/:id" element={<DashboardDepartment />} />
              <Route path="/admin/locations" element={<DashboardLocation />} />
              <Route path="/admin/location/:id" element={<DashboardLocation />} />
              <Route path="/admin/jobs" element={<DashboardJob />} />
              <Route path="/admin/job/:id" element={<DashboardJob />} />
              
              {/* Workflow Routes */}
              <Route path="/admin/workflow" element={<WorkflowOverview />} />
              <Route path="/admin/workflow/actions" element={<Actions />} />
              <Route path="/admin/workflow/action-config" element={<ActionConfig />} />
              <Route path="/admin/workflow/agent-triggers" element={<AgentTriggerConfig />} />
              <Route path="/admin/workflow/approval-groups" element={<ApprovalGroups />} />
              <Route path="/admin/workflow/approval-group-members" element={<ApprovalGroupMembership />} />
              <Route path="/admin/workflow/config-approvals" element={<ConfigApprovals />} />
              <Route path="/admin/workflow/approvals" element={<WorkflowApprovals />} />

              {/* Performance Routes */}
              <Route path="/admin/performance" element={<PerformanceManagement />} />
              
              {/* Company Routes */}
              <Route path="/admin/company/headcount" element={<HeadcountSetup />} />
              
              {/* Analytics Routes */}
              <Route path="/admin/analytics/dashboard" element={<AnalyticsDashboard />} />
              
              {/* Settings & Profile */}
              <Route path="/admin/settings" element={<Settings />} />
              <Route path="/admin/profile" element={<Profile />} />
              
              {/* Fallback */}
              <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
            </Routes>
          </Suspense>
          </MainLayout>
        </ActionsProvider>
      </EmployeeContextProvider>
    </Company_Chart_Data_Provider>
  );
};

export default AuthenticatedApp;
